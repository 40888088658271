export const admin = [
  {
    _name: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['Menu']
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Database Irigasi Tersier',
    route: '/database-irigasi',
    icon: 'cil-file',
    items: [
      {
        name: 'Data Irigasi Tersier Macro',
        to: '/irigasi-macro',
      },
      {
        name: 'Data Irigasi Tersier LP2B',
        to: '/jaringan-irigasi'
      },
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Pemetaan LP2B',
    route: '/database-irigasi',
    icon: 'cil-file',
    items: [
      {
        name: 'Data Lahan LP2B',
        to: '/lp2b',
      },
      {
        name: 'Pemilik Lahan',
        to: '/pemilik-lahan',
      },
      {
        name: 'Kapasitas Produksi',
        to: '/kapasitas-produksi',
      },
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Pengelolaan Data Spasial',
    route: '/database-irigasi',
    icon: 'cil-file',
    items: [
      {
        name: 'Pengelolaan Category',
        to: '/category',
      },
      {
        name: 'Pengelolaan SHP',
        to: '/shp',
      },
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Master User',
    route: '/master-user',
    icon: 'cil-user',
    items: [
      {
        name: 'Kelola User',
        to: '/master-user/user',
      },
      {
        name: 'Kelola Role',
        to: '/master-user/role'
      },
    ]
  },
]


export const opd = [
  {
    _name: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['Menu']
  },

  {
    _name: 'CSidebarNavItem',
    name: 'Agenda Bappeda',
    to: '/agenda',
    icon: 'cil-calendar',
    // badge: {
    //   color: 'primary',
    //   text: 'NEW'
    // }
  },


  {
    _name: 'CSidebarNavDropdown',
    name: 'Data',
    route: '/data',
    icon: 'cil-file',
    items: [
      {
        name: 'Dokumen OPD',
        to: '/data/dokumen-opd',

      },

      {
        name: 'Dokumen Peraturan',
        to: '/data/peraturan'
      },
      {
        name: 'Dokumen Kota',
        to: '/data/kota'
      },
      {
        name: 'Jadwal Perencanaan',
        to: '/data/jadwal-perencanaan'
      },

    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Komunikasi',
    route: '/komunikasi',
    icon: 'cil-comment-square',

    items: [
      {
        name: 'Pesan',
        to: '/komunikasi/pesan',

      },
      {
        name: 'Online Chat',
        to: '/komunikasi/online-chat'
      },

    ]
  },

  {
    _name: 'CSidebarNavItem',
    name: 'History Layanan',
    to: '/history',
    icon: 'cil-grid'
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Laporan Masyarakat',
    to: '/laporan-masyarakat',
    icon: 'cil-pencil'
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Gallery',
    to: '/gallery',
    icon: 'cil-puzzle'
  },
]

export const not_opd = [
  {
    _name: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    // badge: {
    //   color: 'primary',
    //   text: 'NEW'
    // }
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['Menu']
  },

  {
    _name: 'CSidebarNavItem',
    name: 'Agenda Bappeda',
    to: '/agenda',
    icon: 'cil-calendar',
    // badge: {
    //   color: 'primary',
    //   text: 'NEW'
    // }
  },


  {
    _name: 'CSidebarNavDropdown',
    name: 'Data',
    route: '/data',
    icon: 'cil-file',
    items: [
      {
        name: 'Dokumen OPD',
        to: '/data/dokumen-opd',

      },
      // {
      //   name: 'Tipe Dokumen',
      //   to: '/data/tipe-dokumen',

      // },
      {
        name: 'Dokumen Peraturan',
        to: '/data/peraturan'
      },
      {
        name: 'Dokumen Kota',
        to: '/data/kota'
      },
      {
        name: 'Jadwal Perencanaan',
        to: '/data/jadwal-perencanaan'
      },

    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Komunikasi',
    route: '/komunikasi',
    icon: 'cil-comment-square',

    items: [
      {
        name: 'Pesan',
        to: '/komunikasi/pesan',

      },
      {
        name: 'Online Chat',
        to: '/komunikasi/online-chat'
      },

    ]
  },

  {
    _name: 'CSidebarNavItem',
    name: 'History Layanan',
    to: '/history',
    icon: 'cil-grid'
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Laporan Harian',
    to: '/laporan-harian',
    icon: 'cil-pencil'
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Laporan Masyarakat',
    to: '/laporan-masyarakat',
    icon: 'cil-pencil'
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Gallery',
    to: '/gallery',
    icon: 'cil-puzzle'
  },

]